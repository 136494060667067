:focus {
    outline: none;
}
.booking-form form input,
::placeholder, input, textarea, select{
    font-family: "Roboto", sans-serif;
    color: #777777;
}
.preloader{
    display: flex;
}
.offcanvas-wrapper .offcanvas-widget{
    overflow-x: auto;
}
header .nav-container.breakpoint-on .nav-menu .menu-items ul li.menu-item-has-children>a::after{
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}
.booking-form form .inputs-filed .nice-select select {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
}
.input-group.input-group-two .nice-select,
.booking-form form .inputs-filed .nice-select {
    padding: 0;
    border: none;
}
.booking-form form .nice-select, .booking-form form input, .booking-form form select {
    background-color: transparent;
    width: 100%;
    height: 50px;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid #bead8e;
    line-height: 50px;
    color: #777777;
}
.input-group.input-group-two .nice-select::before {
    left: auto;
    right: 30px;
}
.input-group.input-group-two .nice-select select{
    margin-left: 0;
    padding: 0 50px 0 30px;
    -webkit-appearance: none;
    font-size: 14px;
}
.nice-select::before {
    content: '\f063';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    position: absolute;
    left: 30px;
    top: 50%;
    color: #bead8e;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation{
    background: transparent;
    width: 10px;
    height: 10px;
    letter-spacing: normal;
    border: 0.45rem solid transparent;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next{
    border-left-color: #ccc!important;
}
.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous{
    border-right-color: #ccc!important;
}
.latest-news .latest-post-box .post-desc .post-meta{
    font-size: 14px;
}
.latest-news .latest-post-box .post-desc .post-meta {
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.latest-news .latest-post-box .post-desc h4{
    margin-bottom: 15px;
}
.latest-news .latest-post-box .post-desc {
    padding: 25px;
}
.post-box .post-desc .post-footer .author img{
    width:40px;
    height: 40px;
    object-fit: cover;
}
.news-details-box .entry-content .cat + .cat,
.post-box .post-desc .cat + .cat{
    margin-left: 15px;
}
.sidebar .widget.popular-feeds .single-popular-feed .feed-img{
    width: 80px;
    height: 80px;
}
.sidebar .widget.popular-feeds .single-popular-feed .feed-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.news-details-box .entry-footer .post-nav .next-post::before{
    content: '';
    background-image: url('assets/img/blog-details/icon.png');
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}
.counter-section .counter-box.counter-box-two h4{
    display:inline-block;
}
.counter-section .counter-box.counter-box-two h4 + .prefix{
    color: #222;
    letter-spacing: -1px;
    font-family: "Old Standard TT", serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
}
@media (max-width:767px){
    .news-details-box .entry-footer .post-nav .next-post::before{
        display: none;
    }
}


/* Marko  */
.date-special-offer {
    background-color: #1c1c1c;
    /* color: rgb(11, 11, 11); */
    color: #fff;
    margin-top: 10px;
    max-width: 340px;
    /* position: absolute;
    top: -30px; */
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
  }

  .posebna-ponuda-naslov {
    font-size: 40px !important;
  }

  .pogledaj-sve-ponude {
    text-align: center;
    font-size: 24px;
    padding: 20px;
    color: #ffffff !important;
    background-color: #181818;
    display: block;
    margin: auto;
    margin-top: 30px;
    width: 400px;
  }
  .pogledaj-sve-ponude:hover {
    transition: all 0.3s;
    color: #ffffff !important;
    background-color: #0168f5;
  }

  .ms-spec-offer-content {
    background-color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .btn-view-offer-details {
    /* display: block;
    padding: 20px 30px 20px 30px; */
    /* height: 64px; */
    display: inline-block;
    /* background-color: #f2f2f2; */
    margin-top: 0px;
    float: right;
    color: #0168f5;
    font-weight: 600;
    font-size: 18px;
    margin-right: 10px;
  }

  .btn-view-offer-details:hover {
    /* background-color: #0168f5; */
    color: #000;
  }
.pagination-loader {
    transform: scale(0.7);
}

.ur-title-tag {
    font-size: 22px;
}

/* Icon block  */
.ec680921b4 {
    font-size: 13px;
    display: inline-block;
    margin: 3px;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid rgb(238, 238, 238);
}

.ec680921b4:hover{
    font-size: 13px;
    display: inline-block;
    margin: 3px;   
    color: #fff;
    background-color: #0168f5;
    border: 1px solid #0168f5;
    cursor: pointer;
}
.ec680921b4:hover svg {
    fill: #fff !important;
}

/* Icon only  */
.e35246b6fb {
    text-align: center;
}
.svg-tt-bkg {
    
}
.room-desc-standart {
    color: grey;
}

@media (max-width:900px){
    .ur-extra-photos {        
        margin-top: -35px !important;        
    }
    /* .ur-fx-mobile {
        min-height: 600px !important;
    } */
}

 .ur-extra-photos {
    display: inline-block !important;
    margin: 0;
    margin-top: 10px;
    max-width: 173px;
    
}
/*
.ur-extra-photos img {
    
    width: 100%;    
}

.druga, .treca {
    margin-left: 10px;
} */


/* New grid  */
.ur-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 0.9fr);
    grid-gap: 10px;
}

.ur-btn-room-book-now {
    position:absolute;
    bottom: 15px;
    right: 15px;
    /* margin-right: 0px; */
    width: calc(100% - 30px);
}

.starting-price {
    position:absolute;
    right: 25px;
    bottom: 70px;
    font-size: 32px;
    font-weight: bold;
    text-align: right;
    line-height: 28px;
    display: block;
    padding-top: 10px;
    height: 50px;
    width: 400px;
    float: right;
}
.ur-price-and-btn {
    /* height: 135px; */
    min-height: 120px;
    height: 100%;
    background-color: #fff;
    /* position: relative; */
    display:block !important;
}

.ur-room-descs {
    height: 100%; 
}
